<template>
  <main>
    <div class="privacy-hero">
      <div class="bg-privacy-hero"></div>
    </div>

    <section class="container privacy-container bg-white position-relative mb-4">
      <div class="row">
        <div class="col-12">
          <h1 class="mt-3"><strong>Polityka prywatności</strong></h1>

          <hr>

          <p>Topsexrandki.pl dokłada wszelkiej pieczy nad ostrożnym obchodzeniem się z danymi osobowymi pozyskanymi od naszych klientów. Informujemy, że w związku z korzystaniem z naszego serwisu, Państwa dane osobowe są przez nas przetwarzane i zabezpieczane. Przetwarzając dane osobowe, przestrzegamy wymogów ogólnegorozporządzenia o ochronie danych (RODO).</p>

          <p>Topsexrandki.pl jest odpowiedzialny za przetwarzanie danych osobowych zgodnie z niniejszym oświadczeniem o ochronie prywatności.</p>

          <h2>Dane osobowe, które przetwarzamy</h2>

          <p>Topsexrandki.pl przetwarza Twoje dane osobowe, ponieważ korzystasz z naszych usług i / lub ponieważ przekazujesz nam swoje dane za pośrednictwem formularzakontaktowego na naszej stronie internetowej. Poniżej znajdziesz przegląd danych osobowych, które przetwarzamy:</p>

          <ul>
            <li>Imię i/lub nazwisko, wpisane przez Ciebie w formularzu kontaktowym;</li>

            <li>Adres e-mail, wpisany przez Ciebie w formularzu kontaktowym;</li>

            <li>Adres IP;</li>

            <li>Inne dane osobowe, które aktywnie podajesz, takie jak korespondencja;</li>

            <li>Dane o Twoich działaniach na tej stronie;</li>

            <li>Dane o Twoim zachowaniu podczas surfowania po różnych stronach internetowych;</li>

            <li>Przeglądarka internetowa i typ urządzenia.</li>
          </ul>

          <h3>W jakim celu i na jakiej podstawie przetwarzamy dane osobowe?</h3>

          <p>Topsexrandki.pl przetwarza Twoje dane osobowe w następujących celach:</p>

          <ul>
            <li>Aby odpowiedzieć na e-maile wysłane do nas za pośrednictwem formularza kontaktowego;</li>

            <li>Topsexrandki.pl analizuje Twoje zachowanie na stronie internetowej w celu ulepszenia strony internetowej i dostosowania asortymentu produktów i usług do Twoich preferencji.</li>
          </ul>

          <h3>Polityka prywatności dotycząca korzystania z Google Analytics</h3>

          <p>Za pośrednictwem Topsexrandki.pl pliki cookie to miejsca od Google, w ramach usług "Analytics". Korzystamy z tej usługi, aby śledzić i otrzymywać raporty na temat tego, w jaki sposób odwiedzający korzystają ze strony internetowej. Google może przekazywać te informacje stronom trzecim, jeśli Google jest do tego prawnie zobowiązana lub jeśli osoby trzecie przetwarzają te informacje w imieniu Google. Nie mamy na to wpływu. Nie zezwoliliśmy Google na wykorzystywanie uzyskanych informacji do ulepszania innych usług Google. Uzyskane informacje są wykorzystywane wyłącznie w ramach innych usług Google do celów analitycznych. Twój adres IP nie jest podany. Przeczytaj <a href="https://policies.google.com/privacy?hl=pl" target="_blank" rel="noopener noreferrer">politykę prywatności Google, aby</a> uzyskać więcej informacji.</p>

          <p>Korzystając z tej strony internetowej, wyrażasz zgodę na przetwarzanie informacji przez Google w sposób i w celach opisanych powyżej.</p>

          <h3>Prawa dotyczące Twoich danych</h3>

          <p>Masz prawo do wglądu, sprostowania lub usunięcia danych osobowych, które Topsexrandki.pl otrzymał od Ciebie. Możesz również sprzeciwić się przetwarzaniu przez nas Twoich danych osobowych (lub ich części). Ponadto masz prawo do zarządzania danymi o Tobie, które zostały nam przekazane za pośrednictwem Ciebie lub innej strony na Twoje polecenie. Jeśli wcześniej udzieliłeś nam zgody na przetwarzanie twoich danych osobowych, zawsze masz prawo do wycofania tej zgody.</p>

          <h3>Pliki cookie</h3>

          <p>Topsexrandki.pl wykorzystuje również pliki cookie. Są to małe pliki przechowywane na komputerze. Informacje, które są przechowywane, jeśli używasz Topsexrandki.pl. Pomaga nam to przekazać prawidłowe i ważne informacje dla Ciebie jako klienta.</p>

          <h3>Pliki cookie Google Analytics</h3>

          <p>Google Analytics służy do przechowywania plików cookie. Jest to program od Google, który służy do odczytywania wszystkich statystyk. Korzystając z tych plików cookie, Topsexrandki.pl uzyskuje dostęp do następujących elementów:</p>

          <h5>Liczba odwiedzających</h5>

          <ul>
            <li>Które strony są odwiedzane;</li>

            <li>Czas, jaki odwiedzający spędza na stronie internetowej;</li>

            <li>Techniczne pliki cookie.</li>
          </ul>

          <p>Aby działał prawidłowo, ważne jest, aby obecne były techniczne pliki cookie. Te pliki cookie zapewniają optymalne wykorzystanie strony przez użytkownika. Wszystkie podane informacje są w 100% anonimowe, zapewniając Twoją prywatność. Przepisy ogólnego rozporządzenia o ochronie danych (RODO) wskazują, że użytkownicy nie muszą wyrażać zgody na korzystanie z technicznych plików cookie.</p>

          <h3>Marketingowe pliki cookie</h3>

          <p>Marketingowe pliki cookie służą do śledzenia użytkownika w Internecie. Kiedy zgadzasz się na używanie marketingowych plików cookie, Topsexrandki.pl umieszcza tak zwane śledzące pliki cookie. Robimy to, aby móc prezentować Ci spersonalizowane informacje za pośrednictwem kanałów online.</p>

          <h3>Śledzące pliki cookie od reklamodawców</h3>

          <p>Różni reklamodawcy na Topsexrandki.pl umieszczają śledzące pliki cookie na Twoim komputerze. Te pliki cookie są wykorzystywane do następujących celów:</p>

          <ul>
            <li>Śledź, na których stronach kontaktowych się zarejestrowałeś;</li>

            <li>Które banery są dokładnie klikane na naszej stronie internetowej;</li>

            <li>Śledź, jakie informacje są istotne dla odwiedzających i użytkowników naszej strony internetowej.</li>
          </ul>

          <p>Ważne jest, aby pamiętać, że informacje te są anonimowe i nigdy nie będą powiązane z osobą.</p>

          <h3>Skargi</h3>

          <p>Jeśli masz skargę dotyczącą przetwarzania Twoich danych osobowych lub korzystania z plików cookie, prosimy o natychmiastowy kontakt z nami. Jeśli nie możemy wspólnie z Tobą znaleźć rozwiązania, zawsze masz prawo złożyć skargę do holenderskiego organu ochrony danych, jest to organ nadzorczy w dziedzinie ochrony prywatności.</p>

          <h3>Wszelkie pytania</h3>

          <p>Jeśli masz jakiekolwiek pytania lub uwagi dotyczące naszego oświadczenia o ochronie prywatności, skontaktuj się z nami.</p>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: 'Privacy'
}
</script>
