<template>
  <main>
    <section class="bg-hero-table">
      <div class="container">
        <div class="d-flex flex-column justify-content-center text-lg-center pt-4">
          <h1 class="fs-2">Porównanie serwisów randkowych</h1>

          <p class="mb-4">Każdego dnia rodzą się nowe serwisy randkowe. Niektóre wyspecjalizowane, inne nie. Niektóre płatne, niektóre bezpłatne. Tak więc przy tym ogromnym wyborze trudno jest wskazać ten właściwy. Przeanalizowaliśmy dla Ciebie każdą specyfikę czterech najlepszych serwisów randkowych, abyś mógł dokonać najlepszego wyboru spośród wszystkich tych propozycji.</p>

          <h2>Tabela porównawcza</h2>

          <p>Oto tabela porównawcza, która pozwoli Ci lepiej zrozumieć różnice między każdym serwisem randkowym:</p>
        </div>
      </div>
    </section>

    <section class="container">
      <div class="row">
        <div class="col-12">
          <h3 class="d-lg-none mb-3">Top 3 serwisów randkowych</h3>

          <SiteOfferMobileTable
            v-for="site in sites"
            :key="site.id"
            :site="site"
            :getSiteLogoSource="getSiteLogoSource"
            :getSiteImageSource="getSiteImageSource"
            :collapsable="(site.id !== 1) ? true : false"
            :applyIconStyle="applyIconStyle"
          />

          <table class="d-none d-lg-table table table-desktop table-striped bg-white text-center shadow-lg mb-5 mt-n5 p-3">
            <thead>
              <tr class="bg-black text-white">
                <th scope="col">Top 3 serwisów randkowych</th>

                <th
                  scope="col"
                  v-for="site in sites"
                  :key="site.id"
                >{{ site.title }}</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td scope="row"></td>

                <td
                  v-for="site in sites"
                  :key="site.id"
                >
                  <a
                    class="d-block"
                    :href="site.domain_url"
                  >
                    <img
                      class="img-fluid"
                      :src="getSiteLogoSource(site.id)"
                      :alt="site.title"
                    >
                  </a>
                </td>
              </tr>

              <tr>
                <th scope="row">Użytkownicy</th>

                <td
                  v-for="i in 3"
                  :key="i"
                ></td>
              </tr>

              <tr>
                <td scope="row">Przedział wiekowy</td>

                <td
                  :class="{ 'fw-bold': (site.id === 1) }"
                  v-for="site in sites"
                  :key="site.id"
                >{{ site.age_range }}</td>
              </tr>

              <tr>
                <td scope="row">Aktywni członkowie</td>

                <td
                  :class="{ 'fw-bold': (site.id === 1) }"
                  v-for="site in sites"
                  :key="site.id"
                >{{ site.active_members }}</td>
              </tr>

              <tr>
                <td scope="row">Mężczyźni-kobiety</td>

                <td
                  :class="{ 'fw-bold': (site.id === 1) }"
                  v-for="site in sites"
                  :key="site.id"
                >{{ site.men_women }}</td>
              </tr>

              <tr>
                <th scope="row">Funkcjonalność</th>

                <td
                  v-for="i in 3"
                  :key="i"
                ></td>
              </tr>

              <tr>
                <td scope="row">Bezpłatna rejestracja</td>

                <td
                  class="fs-3 fw-bold"
                  :class="applyIconStyle(site.free_registration['style'])"
                  v-for="site in sites"
                  :key="site.id"
                  v-html="site.free_registration['icon']"
                ></td>
              </tr>

              <tr>
                <td scope="row">Wypróbuj za darmo</td>

                <td
                  class="fs-3 fw-bold"
                  :class="applyIconStyle(site.try_for_free['style'])"
                  v-for="site in sites"
                  :key="site.id"
                  v-html="site.try_for_free['icon']"
                ></td>
              </tr>

              <tr>
                <td scope="row">Propozycja profili</td>

                <td
                  class="fs-3 fw-bold"
                  :class="applyIconStyle(site.profile_suggestions['style'])"
                  v-for="site in sites"
                  :key="site.id"
                  v-html="site.profile_suggestions['icon']"
                ></td>
              </tr>

              <tr>
                <td scope="row">Szukaj według kryteriów</td>

                <td
                  class="fs-3 fw-bold"
                  :class="applyIconStyle(site.search_criteria['style'])"
                  v-for="site in sites"
                  :key="site.id"
                  v-html="site.search_criteria['icon']"
                ></td>
              </tr>

              <tr>
                <td scope="row">Obsługa w smartfonie</td>

                <td
                  class="fs-3 fw-bold"
                  :class="applyIconStyle(site.mobile['style'])"
                  v-for="site in sites"
                  :key="site.id"
                  v-html="site.mobile['icon']"
                ></td>
              </tr>

              <tr>
                <td scope="row">Komunikacja</td>

                <td
                  class="fs-3 fw-bold"
                  :class="applyIconStyle(site.communication['style'])"
                  v-for="site in sites"
                  :key="site.id"
                  v-html="site.communication['icon']"
                ></td>
              </tr>

              <tr>
                <td scope="row">Czat</td>

                <td
                  class="fs-3 fw-bold"
                  :class="applyIconStyle(site.chat['style'])"
                  v-for="site in sites"
                  :key="site.id"
                  v-html="site.chat['icon']"
                ></td>
              </tr>

              <tr>
                <td scope="row">Kamera internetowa</td>

                <td
                  class="fs-3 fw-bold"
                  :class="applyIconStyle(site.webcam['style'])"
                  v-for="site in sites"
                  :key="site.id"
                  v-html="site.webcam['icon']"
                ></td>
              </tr>

              <tr>
                <th scope="row">Kluczowe punkty</th>

                <td
                  v-for="i in 3"
                  :key="i"
                ></td>
              </tr>

              <tr>
                <td scope="row">Bezpieczeństwo</td>

                <td
                  :class="{ 'fw-bold': (site.id === 1) }"
                  v-for="site in sites"
                  :key="site.id"
                >{{ site.security }}</td>
              </tr>

              <tr>
                <td scope="row">Ergonomia</td>

                <td
                  :class="{ 'fw-bold': (site.id === 1) }"
                  v-for="site in sites"
                  :key="site.id"
                >{{ site.ergonomics }}</td>
              </tr>

              <tr>
                <td scope="row">Obsługa klienta</td>

                <td
                  :class="{ 'fw-bold': (site.id === 1) }"
                  v-for="site in sites"
                  :key="site.id"
                >{{ site.customer_service }}</td>
              </tr>

              <tr>
                <th scope="row">Cena</th>

                <td
                  v-for="i in 3"
                  :key="i"
                ></td>
              </tr>

              <tr>
                <td scope="row">Korzystanie</td>

                <td
                  :class="{ 'fw-bold': (site.id === 1) }"
                  v-for="site in sites"
                  :key="site.id"
                >{{ site.operating_mode }}</td>
              </tr>

              <tr>
                <td scope="row"></td>

                <td
                  v-for="site in sites"
                  :key="site.id"
                >
                  <div class="fs-2 fw-bold text-danger mb-3">{{ site.rating }}</div>

                  <a
                    class="btn btn-warning w-100 mb-3"
                    :href="site.domain_url"
                  >Odwiedź stronę</a>

                  <a
                    class="d-block w-100"
                    :href="site.domain_url"
                  >
                    <img
                      class="img-fluid"
                      :src="getSiteLogoSource(site.id)" alt="Snapdate.es">
                  </a>
                </td>
              </tr>
            </tbody>
          </table>

          <h3>Cztery wskazówki dotyczące wyboru serwisu randkowego, który najbardziej Ci odpowiada:</h3>

          <ol class="mb-4">
            <li>Pierwszym kluczowym krokiem przy wyborze serwisu randkowego jest zidentyfikowanie swoich oczekiwań co do serwisu randkowego, który będzie adekwatny do ich potrzeb. Najważniejszą zatem kwestią jest określenie tego czego faktycznie szukasz na danym portalu. Czego oczekujesz ze strony Portalu. Jakie są Twoje oczekiwania. Kogo chciałbyś znaleźć. Im bardziej rzeczowo określisz swoje potrzeby, tym łatwiej nam będzie dopasować kogoś idealne pasującego dla Ciebie.</li>
            <li>Po drugie, pamiętaj, aby wybrać serwis randkowy, który pasuje do Twojego przedziału wiekowego. Faktycznie jeśli masz 65 lat, ale rejestrujesz się na portalu randkowym, który ma młodszą grupę wiekową, trudniej będzie Ci znaleźć bratnią duszę i odwrotnie. Dlatego ważne jest, aby przed rejestracją przyjrzeć się przedziałowi wiekowemu, do którego skierowany jest serwis randkowy.</li>
            <li>Wybierz serwis randkowy, który pasuje do Ciebie najlepiej. Dowiedz się o cenach  ale także o warunkach użytkowania, aby dokonać wyboru, który najlepiej odpowiada Twoim potrzebom. Ważne jest również, aby czuć się komfortowo z interfejsem serwisu randkowego, ponieważ to tutaj będziesz prowadzić rozmowy z potencjalnym partnerkami (partnerami). Jeśli chcesz używać smartfona, tabletu do rozmów z przyszłymi partnerkami (partnerami), zwróć uwagę na zgodność witryny z tym wsparciem.</li>
            <li>Wreszcie, nie wahaj się przetestować serwisy randkowe. Uważaj jednak, aby nie tworzyć kilku profili w jednym serwisie randkowym, ponieważ może to zostać zauważone przez użytkowników i zniechęcić ich. Z drugiej strony możesz być zarejestrowany na dwóch lub trzech różnych portalach randkowych, aby dać sobie więcej szans na poznanie idealnej partnerki (partnera) Powodzenia!</li>
          </ol>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import SiteOfferMobileTable from '@/components/SiteOfferMobileTable.vue'

export default {
  name: 'Table',
  props: ['sites', 'getSiteLogoSource', 'getSiteImageSource'],
  components: {
    SiteOfferMobileTable
  },
  methods: {
    applyIconStyle (hasStyle) {
      let applyStyle = ''
      switch (hasStyle) {
        case true:
          applyStyle = 'text-success'
          break
        case false:
          applyStyle = 'text-danger'
          break
        case null:
          applyStyle = 'text-warning'
          break
        default:
          break
      }
      return applyStyle
    }
  }
}
</script>
