<template>
  <aside class="col-md-4">
    <div class="sticky-top mb-3">
      <div class="card mb-4">
        <div class="card-body p-1">
          <a
            class="d-block position-relative mx-2"
            :href="site.domain_url"
            :data-site-id="site.data_id"
          >
            <div class="triangle triangle-primary position-absolute">
              <span class="aside-number-position position-absolute text-white fs-3 fw-bold">{{ site.id }}</span>
            </div>

            <img
              class="img-fluid rounded"
              :src="getSiteImageSource(site.id)"
              :alt="site.title"
            >

            <div class="position-absolute aside-badge-logo bg-warning text-center bottom-0 start-0 end-0">
              <img
                class="img-fluid"
                :src="getSiteLogoSource(site.id)"
                :alt="site.title">
            </div>
          </a>

          <hr class="mx-2">

          <div class="border d-flex justify-content-center text-center mx-2">
            <div class="border-end px-2">
              <div class="my-3">Nasz wynik</div>

              <div class="fs-1 fw-bold lh-lg">{{ site.rating }}</div>
            </div>

            <div class="px-2">
              <div class="my-3">Czas do pierwszego kontaktu <br> <span class="text-danger">10 min</span></div>

              <div class="mb-3">Czas na pierwszą randkę <br> <span class="text-danger">2 dni</span></div>
            </div>
          </div>

          <hr class="mx-2">

          <div class="d-flex justify-content-center px-2">
            <a
              class="btn btn-warning shadow w-100 mb-2"
              :href="site.domain_url"
              :data-site-id="site.data_id"
            >Odwiedź {{ site.title }}</a>
          </div>
        </div>
      </div>

      <div class="bg-danger p-2 mb-2">
        <h5 class="text-center text-white mb-0">Pierwsza 3</h5>
      </div>

      <SiteOfferAsideCard
        v-for="site in sites"
        :key="site.id"
        :site="site"
        :getSiteLogoSource="getSiteLogoSource"
        :getSiteImageSource="getSiteImageSource"
      />
    </div>
  </aside>
</template>

<script>
import SiteOfferAsideCard from '@/components/SiteOfferAsideCard.vue'

export default {
  name: 'SiteOfferAside',
  props: ['sites', 'site', 'getSiteLogoSource', 'getSiteImageSource'],
  components: {
    SiteOfferAsideCard
  }
}
</script>
