<template>
  <table class="d-lg-none table table-striped table-mobile bg-white text-center shadow-lg mb-4">
    <thead>
      <tr class="bg-black" v-if="collapsable">
        <th scope="col" colspan="2">
          <button
            class="btn fw-bold text-white p-0 w-100"
            type="button"
            data-bs-toggle="collapse"
            :data-bs-target="`#${site.name}`"
            aria-expanded="false"
            :aria-controls="site.name"
            @click="toggleCollapseIcon"
          >
            {{ site.title }}
            <i
              class="ms-1"
              :class="(show) ? 'bi-chevron-up' : 'bi-chevron-down'"
            ></i>
          </button>
        </th>
      </tr>
      <tr class="bg-black text-white" v-else>
        <th scope="col" colspan="2">{{ site.title }}</th>
      </tr>
    </thead>

    <tbody
      :class="{ collapse: collapsable }"
      :id="site.name"
    >
      <tr>
        <td class="p-3" scope="row" colspan="2">
          <a
            class="d-block"
            :href="site.domain_url"
            :data-site-id="site.data_id"
          >
            <img
              class="img-fluid"
              :src="getSiteLogoSource(site.id)"
              :alt="site.title"
            >
          </a>
        </td>
      </tr>

      <tr>
        <td class="p-3" scope="row">Przedział wiekowy</td>
        <td class="p-3"><strong>{{ site.age_range }}</strong></td>
      </tr>

      <tr>
        <td class="p-3" scope="row">Aktywni członkowie</td>
        <td class="p-3">{{ site.active_members }}</td>
      </tr>

      <tr>
        <td class="p-3" scope="row">Mężczyźni-kobiety</td>
        <td class="p-3"><strong>{{ site.men_women }}</strong></td>
      </tr>

      <tr>
        <td class="p-3" scope="row">Bezpłatna rejestracja</td>
        <td
          class="fs-5 fw-bold p-3"
          :class="applyIconStyle(site.free_registration['style'])"
          v-html="site.free_registration['icon']"
        ></td>
      </tr>

      <tr>
        <td class="p-3" scope="row">Wypróbuj za darmo</td>
        <td
          class="fs-5 fw-bold p-3"
          :class="applyIconStyle(site.try_for_free['style'])"
          v-html="site.try_for_free['icon']"
        ></td>
      </tr>

      <tr>
        <td class="p-3" scope="row">Propozycja profili</td>
        <td
          class="fs-5 fw-bold p-3"
          :class="applyIconStyle(site.profile_suggestions['style'])"
          v-html="site.profile_suggestions['icon']"
        ></td>
      </tr>

      <tr>
        <td class="p-3" scope="row">Szukaj według kryteriów</td>
        <td
          class="fs-5 fw-bold p-3"
          :class="applyIconStyle(site.search_criteria['style'])"
          v-html="site.search_criteria['icon']"
        ></td>
      </tr>

      <tr>
        <td class="p-3" scope="row">Obsługa w smartfonie</td>
        <td
          class="fs-5 fw-bold p-3"
          :class="applyIconStyle(site.mobile['style'])"
          v-html="site.mobile['icon']"
        ></td>
      </tr>

      <tr>
        <td class="p-3" scope="row">Komunikacja</td>
        <td
          class="fs-5 fw-bold p-3"
          :class="applyIconStyle(site.communication['style'])"
          v-html="site.communication['icon']"
        ></td>
      </tr>

      <tr>
        <td class="p-3" scope="row">Czat</td>
        <td
          class="fs-5 fw-bold p-3"
          :class="applyIconStyle(site.chat['style'])"
          v-html="site.chat['icon']"
        ></td>
      </tr>

      <tr>
        <td class="p-3" scope="row">Kamera internetowa</td>
        <td
          class="fs-5 fw-bold p-3"
          :class="applyIconStyle(site.webcam['style'])"
          v-html="site.webcam['icon']"
        ></td>
      </tr>

      <tr>
        <td class="p-3" scope="row">Bezpieczeństwo</td>
        <td class="p-3"><strong>{{ site.security }}</strong></td>
      </tr>

      <tr>
        <td class="p-3" scope="row">Ergonomia</td>
        <td class="p-3"><strong>{{ site.ergonomics }}</strong></td>
      </tr>

      <tr>
        <td class="p-3" scope="row">Obsługa klienta</td>
        <td class="p-3"><strong>{{ site.customer_service }}</strong></td>
      </tr>

      <tr>
        <td class="p-3" scope="row">Korzystanie</td>
        <td class="p-3"><strong>{{ site.operating_mode }}</strong></td>
      </tr>

      <tr>
        <td class="p-3" scope="row"></td>
        <td class="p-3">
          <div class="fs-1 fw-bold text-danger mb-3">{{ site.rating }}</div>
          <a
            class="btn btn-warning text-nowrap w-100 mb-3"
            :href="site.domain_url"
            :data-site-id="site.data_id"
          >Odwiedź stronę</a>

          <a
            class="d-block"
            :href="site.domain_url"
            :data-site-id="site.data_id"
          >
            <img
              class="img-fluid"
              :src="getSiteLogoSource(site.id)"
              :alt="site.title"
            >
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'SiteOfferMobileTable',
  props: ['site', 'getSiteLogoSource', 'getSiteImageSource', 'collapsable', 'applyIconStyle'],
  data () {
    return {
      show: false
    }
  },
  methods: {
    toggleCollapseIcon () {
      this.show = !this.show
    }
  }
}
</script>
