import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Table from '../views/Table.vue'
import Privacy from '../views/Privacy.vue'
import Snapdate from '../views/Snapdate.vue'
import Flirtwedwoje from '../views/Flirtwedwoje.vue'
import Zmyslowyklub from '../views/Zmyslowyklub.vue'
import Gay from '../views/Gay.vue'
import GayReview from '../views/GayReview.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/tabela/',
    name: 'table',
    component: Table
  },
  {
    path: '/prywatnosci/',
    name: 'privacy',
    component: Privacy
  },
  {
    path: '/snapdate/',
    name: 'snapdate',
    component: Snapdate
  },
  {
    path: '/flirtwedwoje/',
    name: 'flirtwedwoje',
    component: Flirtwedwoje
  },
  {
    path: '/zmyslowyklub/',
    name: 'zmyslowyklub',
    component: Zmyslowyklub
  },
  {
    path: '/gay/',
    name: 'gay',
    component: Gay
  },
  {
    path: '/gay/gay-review/',
    name: 'gayReview',
    component: GayReview
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from) {
    return {
      behavior: 'smooth',
      top: 0
    }
  }
})

export default router
