<template>
  <footer class="footer bg-black mt-3">
    <div class="container text-white text-center pt-3 pb-5">
      <div class="row mb-5">
        <div class="col-12">
          <p>Copyright &copy; {{ getTodayYear }} &VerticalSeparator; Topsexrandki.pl</p>

          <ul class="nav justify-content-center">
            <li class="nav-item">
              <a class="nav-link" href="mailto:info@topsexrandki.pl">E-mail: info@topsexrandki.pl</a>
            </li>
            <li class="nav-item">
              <router-link
                class="nav-link"
                :to="{ name: 'privacy' }"
              >Prywatności</router-link>
            </li>
          </ul>
        </div>
      </div>

      <div class="row">
        <div class="d-none d-md-block col-md-6">
          <img class="img-fluid" src="../assets/img/topsexrandki.pl.png" alt="Topsexrandki.pl">
        </div>

        <div class="col-12 col-md-6">
          <h5 class="fs-3 text-white-50">Najlepiej oceniane strony <span class="text-danger">randkowe</span>!</h5>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'TheFooter',
  computed: {
    getTodayYear () {
      const today = new Date()
      const year = today.getFullYear()
      return `${year}`
    }
  }
}
</script>
