<template>
  <div
    class="review-banner fixed-top bg-black py-3"
    v-show="hasScrolled"
  >
    <div class="container">
      <div class="row align-items-center">
        <div class="col-sm-6 col-md-3">
          <div class="fs-4 text-center text-white">Nasz wynik to:</div>
          <div class="fs-3 fw-bold text-center text-warning">{{ site.rating }}</div>
        </div>

        <div class="col-sm-6 col-md-6 d-none d-sm-block">
          <div class="fs-4 text-center text-white">Opinia klienta:</div>
          <div class="text-center">
            <span
              v-for="i in parseInt(site.stars)"
              :key="i"
            >
              <i class="bi-star-fill fs-4 text-warning"></i>

              <span v-if="i < parseInt(site.stars)">&ensp;</span>
            </span>
          </div>
        </div>

        <div class="col-sm-12 col-md-3">
          <a
            class="btn btn-danger border-bottom border-bottom-warning fw-bold shadow text-uppercase mt-3 mt-md-0 w-100"
            :href="site.domain_url"
            :data-site-id="site.data_id"
            target="_blank"
            rel="noopener noreferrer"
          >Dołącz za darmo już teraz!</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SiteOfferReviewBanner',
  props: ['site'],
  data () {
    return {
      hasScrolled: false
    }
  },
  methods: {
    showBanner () {
      this.hasScrolled = window.scrollY > 0
    }
  },
  created () {
    window.addEventListener('scroll', this.showBanner)
  },
  unmounted () {
    window.removeEventListener('scroll', this.showBanner)
  }
}
</script>
