<template>
  <div class="bg-hero-review"></div>

  <div class="container">
    <div class="row">
      <h1 class="my-4">
        <a
          class="text-decoration-none"
          :href="site.domain_url"
          :data-site-id="site.data_id"
        >{{ site.title }}</a>
      </h1>

      <main class="col-md-8">
        <div class="card mb-3">
          <div class="card-body">
            <h2 class="card-title mb-3">Co to jest <a class="text-decoration-none" :href="site.domain_url" :data-site-id="site.data_id">{{ site.title }}</a>?</h2>

            <div
              class="mb-3"
              v-if="Array.isArray(site.what_is_site)"
            >
              <p
                class="card-text"
                v-for="(item, i) in site.what_is_site"
                :key="i"
              >{{ item }}</p>
            </div>

            <p
              class="card-text"
              v-else
            >{{ site.what_is_site }}</p>

            <h5 class="card-title mb-3">Jak to działa?</h5>

            <div
              class="mb-3"
              v-if="Array.isArray(site.how_it_works)"
            >
              <p
                class="card-text"
                v-for="(item, i) in site.how_it_works"
                :key="i"
              >{{ item }}</p>
            </div>

            <p
              class="card-text"
              v-else
            >{{ site.how_it_works }}</p>

            <a
              class="btn btn-warning rounded shadow w-100"
              :href="site.domain_url"
              :data-site-id="site.data_id"
            >Odwiedź {{ site.title }}!</a>

            <h5 class="card-title mt-4 mb-3">Czym się różni od innych serwisów randkowych?</h5>

            <div
              class="mb-3"
              v-if="Array.isArray(site.what_is_different)"
            >
              <p
                class="card-text"
                v-for="(item, i) in site.what_is_different"
                :key="i"
              >{{ item }}</p>
            </div>

            <p
              class="card-text"
              v-else
            >{{ site.what_is_different }}</p>

            <h5 class="card-title mb-3">Obsługa klienta i bezpieczeństwo</h5>

            <div
              class="mb-3"
              v-if="Array.isArray(site.service_and_security)"
            >
              <p
                class="card-text"
                v-for="(item, i) in site.service_and_security"
                :key="i"
              >{{ item }}</p>
            </div>

            <p
              class="card-text"
              v-else
            >{{ site.service_and_security }}</p>

            <h5 class="card-title mb-3">Nasza opinia na temat <a class="text-decoration-none" :href="site.domain_url" :data-site-id="site.data_id">{{ site.title }}</a></h5>

            <div
              class="mb-3"
              v-if="Array.isArray(site.our_opinion)"
            >
              <p
                class="card-text"
                v-for="(item, i) in site.our_opinion"
                :key="i"
              >{{ item }}</p>
            </div>

            <p
              class="card-text"
              v-else
            >{{ site.our_opinion }}</p>

            <h5 class="card-title mb-3">Największe zalety:</h5>

            <ul>
              <li
                v-for="(item, i) in site.fees_and_benefits"
                :key="i"
              >{{ item }}</li>
            </ul>

            <h5
              class="card-title mb-3"
              v-if="site.price_lists"
            >Przegląd cen:</h5>

            <div
              class="mb-3"
              v-if="site.price_lists && Array.isArray(site.price_lists)"
            >
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Ceny Paczek Kredytów</th>
                    <th scope="col">Cena</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(list_price, i) in site.price_lists"
                    :key="i"
                  >
                    <td>{{ list_price['credit'] }}</td>
                    <td>{{ list_price['price'] }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <h5
              class="card-title mb-3"
              v-if="site.our_report"
            >Nasz raport</h5>

            <div
              class="mb-3"
              v-if="site.our_report && Array.isArray(site.our_report)"
            >
              <table class="table">
                <tbody>
                  <tr
                    v-for="(item, i) in site.our_report"
                    :key="i"
                  >
                    <th scope="row">{{ item['name'] }}</th>
                    <td>
                      <span v-html="ourReportStarRating(parseInt(item['rating']))"></span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <a
              class="btn btn-warning rounded shadow w-100"
              :href="site.domain_url"
              :data-site-id="site.data_id"
            >Odwiedź {{ site.title }}!</a>
          </div>
        </div>
      </main>

      <SiteOfferAside
        :sites="sites"
        :site="site"
        :getSiteLogoSource="getSiteLogoSource"
        :getSiteImageSource="getSiteImageSource"
      />
    </div>
  </div>

  <SiteOfferReviewBanner :site="site" />
</template>

<script>
import SiteOfferAside from '@/components/SiteOfferAside.vue'
import SiteOfferReviewBanner from '@/components/SiteOfferReviewBanner.vue'

export default {
  name: 'SiteOfferReview',
  props: ['sites', 'site', 'getSiteLogoSource', 'getSiteImageSource'],
  components: {
    SiteOfferAside,
    SiteOfferReviewBanner
  },
  methods: {
    ourReportStarRating (reportRating) {
      // Calculate the rating difference out
      // of 5 to display filled stars and stars
      let stars = ''
      const starFilled = '<i class="bi bi-star-fill text-warning"></i>'
      const star = '<i class="bi bi-star text-warning"></i>'

      for (let i = 0; i < parseInt(reportRating); i++) {
        stars += starFilled

        if (parseInt(reportRating) === 5) {
          if (i < parseInt(reportRating) - 1) {
            stars += '&nbsp;'
          }
        } else {
          stars += '&nbsp;'
        }
      }

      if (reportRating < 5) {
        const emptyStars = 5 - parseInt(reportRating)
        for (let i = 0; i < emptyStars; i++) {
          stars += star

          if (i < emptyStars - 1) {
            stars += '&nbsp;'
          }
        }
      }

      return stars
    }
  }
}
</script>
