<template>
  <main>
    <section class="bg-hero-home">
      <div class="container">
        <div class="d-flex flex-column justify-content-center text-center pt-4">
          <h1 class="fs-2 text-white">Ranking najlepszych serwisów randkowych dla gejów &check; <span class="text-capitalize">{{ getTodayDate }}</span></h1>

          <p class="d-none d-lg-block lead fw-normal text-white">Szukasz zmysłowych i liberalnych spotkań? W takim razie ten ranking jest dla Ciebie! Dzięki ocenie naszego zespołu oraz opinii internautów przedstawiamy Wam listę najlepszych stron gejowskich dla dorosłych, aby wreszcie udało się znaleźć idealnego partnera! Porównaj, przetestuj i znajdź swojego partnera!</p>
        </div>
      </div>
    </section>

    <section class="container">
      <div class="row">
        <div class="col-12">
          <table class="table bg-white shadow-lg mb-4 mt-n5">
            <thead class="text-center">
              <tr class="bg-black text-white">
                <th scope="col">Strona internetowa</th>
                <th class="d-none d-md-table-cell" scope="col">Zalety</th>
                <th class="d-none d-lg-table-cell" scope="col">Użytkownicy</th>
                <th class="d-none d-md-table-cell" scope="col">Ocena</th>
                <th scope="col">Informacje</th>
              </tr>
            </thead>

            <tbody>
              <!-- First Site -->
              <tr class="table-danger">
                <th scope="row">
                  <a
                    class="d-block position-relative"
                    :href="sites[0].domain_url"
                    :data-site-id="sites[0].data_id"
                  >
                    <div class="triangle triangle-primary position-absolute">
                      <span class="number-position position-absolute text-white fs-3 fw-bold">{{ sites[0].id }}</span>
                    </div>

                    <img
                      class="img-fluid"
                      :src="getSiteImageSource(sites[0].id)"
                      :alt="sites[0].title"
                    >

                    <div class="position-absolute badge-logo badge-logo-first">
                      <img
                        class="img-fluid"
                        :src="getSiteLogoSource(sites[0].id)"
                        :alt="sites[0].title"
                      >
                    </div>
                  </a>
                </th>

                <td class="d-none d-md-table-cell">
                  <ul class="list-check">
                    <li
                      v-for="(item, i) in sites[0].usps"
                      :key="i"
                      v-html="item"
                    ></li>
                  </ul>
                </td>

                <td class="d-none d-lg-table-cell">
                  <p><span class="text-danger"><strong>{{ sites[0].active_members }}</strong></span> singli</p>

                  <p>Przedział wiekowy:<br><span class="text-danger"><strong>{{ sites[0].age_range }}</strong></span></p>
                </td>

                <td class="d-none d-md-table-cell text-center">
                  <div class="text-danger fs-2 fw-bold">{{ sites[0].rating }}</div>
                  <div class="text-danger">{{ sites[0].number_of_user_rates }}</div>
                </td>

                <td>
                  <div class="d-sm-none">
                    <ul class="list-check mb-1">
                      <li v-html="sites[0].usps[0]"></li>
                      <li v-html="sites[0].usps[1]"></li>
                    </ul>

                    <div class="text-center mb-1">
                      <i class="bi-star-fill text-warning"></i>&ensp;{{ `${sites[0].rating} ${sites[0].number_of_user_rates}` }}
                    </div>
                  </div>

                  <div class="text-center mb-3">
                    <a
                      class="btn btn-warning shadow"
                      :href="sites[0].domain_url"
                      :data-site-id="sites[0].data_id"
                    >Odwiedź stronę internetową</a>
                  </div>

                  <div class="d-none d-sm-block text-center">
                    <router-link
                      class="text-decoration-none text-dark"
                      :to="{ name: sites[0].name }"
                    >Dowiedz się więcej</router-link>
                  </div>
                </td>
              </tr>
              <!-- Second Site -->
              <tr>
                <th scope="row">
                  <a
                    class="d-block position-relative"
                    :href="sites[1].domain_url"
                    :data-site-id="sites[1].data_id"
                  >
                    <div class="triangle triangle-primary position-absolute">
                      <span class="number-position position-absolute text-white fs-3 fw-bold">{{ sites[1].id }}</span>
                    </div>

                    <img
                      class="img-fluid"
                      :src="getSiteImageSource(sites[1].id)"
                      :alt="sites[1].title"
                    >

                    <div class="position-absolute badge-logo badge-logo-second">
                      <img
                        class="img-fluid"
                        :src="getSiteLogoSource(sites[1].id)"
                        :alt="sites[1].title"
                      >
                    </div>
                  </a>
                </th>

                <td class="d-none d-md-table-cell">
                  <ul class="list-check">
                    <li
                      v-for="(item, i) in sites[1].usps"
                      :key="i"
                      v-html="item"
                    ></li>
                  </ul>
                </td>

                <td class="d-none d-lg-table-cell">
                  <p><span class="text-danger"><strong>{{ sites[1].active_members }}</strong></span> singli</p>
                  <p>Przedział wiekowy:<br><span class="text-danger"><strong>{{ sites[1].age_range }}</strong></span></p>
                </td>

                <td class="d-none d-md-table-cell text-center">
                  <div class="text-danger fs-2 fw-bold">{{ sites[1].rating }}</div>
                  <div class="text-danger">{{ sites[1].number_of_user_rates }}</div>
                </td>

                <td>
                  <div class="d-sm-none">
                    <ul class="list-check mb-1">
                      <li v-html="sites[1].usps[0]"></li>
                    </ul>

                    <div class="text-center mb-1">
                      <i class="bi-star-fill text-warning"></i>&ensp;{{ `${sites[1].rating} ${sites[1].number_of_user_rates}` }}
                    </div>
                  </div>

                  <div class="text-center mb-3">
                    <a
                      class="btn btn-warning shadow"
                      :href="sites[1].domain_url"
                      :data-site-id="sites[1].data_id"
                    >Odwiedź stronę internetową</a>
                  </div>

                  <div class="d-none d-sm-block text-center">
                    <router-link
                      class="text-decoration-none text-dark"
                      :to="{ name: sites[1].name }"
                    >Dowiedz się więcej</router-link>
                  </div>
                </td>
              </tr>
              <!-- Third Site -->
              <tr>
                <th scope="row">
                  <a
                    class="d-block position-relative"
                    :href="sites[2].domain_url"
                    :data-site-id="sites[2].data_id"
                  >
                    <div class="triangle triangle-primary position-absolute">
                      <span class="number-position position-absolute text-white fs-3 fw-bold">{{ sites[2].id }}</span>
                    </div>

                    <img
                      class="img-fluid"
                      :src="getSiteImageSource(sites[2].id)"
                      :alt="sites[2].title"
                    >

                    <div class="position-absolute badge-logo badge-logo-third">
                      <img
                        class="img-fluid"
                        :src="getSiteLogoSource(sites[2].id)"
                        :alt="sites[2].title"
                      >
                    </div>
                  </a>
                </th>

                <td class="d-none d-md-table-cell">
                  <ul class="list-check">
                    <li
                      v-for="(item, i) in sites[2].usps"
                      :key="i"
                      v-html="item"
                    ></li>
                  </ul>
                </td>

                <td class="d-none d-lg-table-cell">
                  <p><span class="text-danger"><strong>{{ sites[2].active_members }}</strong></span> singli</p>
                  <p>Przedział wiekowy:<br><span class="text-danger"><strong>{{ sites[2].age_range }}</strong></span></p>
                </td>

                <td class="d-none d-md-table-cell text-center">
                  <div class="text-danger fs-2 fw-bold">{{ sites[2].rating }}</div>
                  <div class="text-danger">{{ sites[2].number_of_user_rates }}</div>
                </td>

                <td>
                  <div class="d-sm-none">
                    <ul class="list-check mb-1">
                      <li v-html="sites[2].usps[0]"></li>
                    </ul>

                    <div class="text-center mb-1">
                      <i class="bi-star-fill text-warning"></i>&ensp;{{ `${sites[2].rating} ${sites[2].number_of_user_rates}` }}
                    </div>
                  </div>

                  <div class="text-center mb-3">
                    <a
                      class="btn btn-warning shadow"
                      :href="sites[2].domain_url"
                      :data-site-id="sites[2].data_id"
                    >Odwiedź stronę internetową</a>
                  </div>

                  <div class="d-none d-sm-block text-center">
                    <router-link
                      class="text-decoration-none text-dark"
                      :to="{ name: sites[2].name }"
                    >Dowiedz się więcej</router-link>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: 'Gay',
  props: ['sites', 'getSiteLogoSource', 'getSiteImageSource', 'getTodayDate']
}
</script>
