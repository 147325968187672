<template>
  <header>
    <div class="container-fluid">
      <div class="d-flex align-items-center py-3">
        <div>
          <router-link :to="{ name: 'home' }">
            <img class="img-fluid" src="../assets/img/topsexrandki.pl.png" alt="Topsexrandki.pl">
          </router-link>
        </div>

        <div class="ms-auto">
          <button class="btn btn-light text-nowrap" type="button" data-bs-toggle="offcanvas" data-bs-target="#mainNav" aria-controls="mainNav" aria-expanded="false" aria-label="Toggle navigation">Menu &#9776;</button>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'TheHeader'
}
</script>
